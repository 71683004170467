import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HomeQuotesStyles, RiskContainer } from './styles/HomeResultPage.styles';
import HomeInfoCard from '../../common/components/redesignComponents/Cards/HomeInfoCard';
import { GuaranteesDefaultStateStyles } from '../../common/components/redesignComponents/common/GuaranteesDefaultState.styles';
import { EstimateContainer } from '../../common/components/redesignComponents/common/Redesign.styles';
import {
  addHouseQuoteToCart,
  houseQuotesGetQuotes,
  removeHouseQuoteFromCart,
} from '../../features/insuranceRequests/insuranceRequestsActions';
import QuoteGenerateLoading from '../../common/components/elements/QuoteGenerateLoading';
import ErrorComponent from '../../common/components/ErrorComponent';
import { quotationTypes } from '../../common/constants';
import { confirmAlert, generalAlertError } from '../../common/utils/alerts';
import HouseModal from './components/HouseModal';
import HouseModalButtons from './components/HouseModalButtons';

function HomeResultPage() {
  const { requestToken } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const {
    loading,
    error,
    insuranceRequest,
    customer,
    quotations,
    addingQuote,
    processingQuote,
    riskQuotations,
  } = useSelector((store) => store.insuranceRequests.house_quotes);

  const refresh = () => houseQuotesGetQuotes(requestToken);

  const selectQuotation = (quoteId, closeButton) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addHouseQuoteToCart(quoteId, refresh, closeButton));
  };

  const removeItemFromCart = (quoteId) => {
    confirmAlert('Sei Sicuro di rimuovere questa quotazione?', () => removeHouseQuoteFromCart(quoteId, refresh, () => {}));
  };

  useEffect(() => {
    houseQuotesGetQuotes(requestToken);
  }, [requestToken]);

  const handleRequestPurchase = () => {
    if (processingQuote === null) {
      generalAlertError('Seleziona Prima La Responsabilita Civile', 'Errore');
      return;
    }

    navigate(`/quotes/casa/purchase/${requestToken}`);
  };

  const modifyData = () => {
    navigate('/quote/casa', {
      state: { requestToken },
    });
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let risksTotalAmount = 0;

    if (processingQuote !== null) {
      quoteInProccessAmount = parseFloat(processingQuote.amount);
    }

    const riskTypes = quotationTypes.filter((t) => t.id !== 'res_civile');

    for (let x = 0; x < riskTypes.length; x++) {
      const type = riskTypes[x];
      if (riskQuotations && riskQuotations[type.id] !== undefined) {
        for (let i = 0; i < riskQuotations[type.id].length; i++) {
          const item = riskQuotations[type.id][i];

          risksTotalAmount += parseFloat(item.amount);
        }
      }
    }

    return (quoteInProccessAmount + risksTotalAmount).toFixed(2);
  };

  if (loading) {
    return <QuoteGenerateLoading countNr={80} />;
  }

  if (error !== null) {
    return <ErrorComponent error={error} />;
  }

  return (
    <>
      <HomeQuotesStyles>
        <div className="resultsPage">
          <div className="row">
            <div className="col-12 col-xxl-4 mb-2 mb-xxl-0 result-home-first-step">
              <HomeInfoCard
                customer={customer}
                insuranceRequest={insuranceRequest}
                modifyData={modifyData}
              />
            </div>
            <div className="col-12 col-xxl-8 result-home-second-step">
              <GuaranteesDefaultStateStyles>
                {processingQuote === null ? (
                  <h5 className="fw-semibold pt-2 ps-lg-2">
                    Seleziona Le Quotazioni Che Vuoi Aquistare
                  </h5>
                ) : (
                  <div className="container mt-4">
                    <ul className="list-unstyled">
                      {processingQuote !== null && (
                        <li>
                          <div className="d-flex justify-content-between align-items-center">
                            <h5>
                              {processingQuote.company.name}
                              {' '}
                              <span className="text-success">
                                (
                                {processingQuote.product_name}
                                )
                              </span>
                            </h5>
                            <h5 className="fw-bold">
                              {parseFloat(processingQuote.amount).toFixed(2)}
                              {' '}
                              €
                            </h5>
                          </div>
                        </li>
                      )}
                      {quotationTypes
                        .filter((t) => t.id !== 'res_civile')
                        .map((type, index) => (
                          <React.Fragment key={index}>
                            {riskQuotations && riskQuotations[type.id]
                              ? riskQuotations[type.id].map((quote, i) => (
                                <li key={i}>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h5>
                                      {quote.company !== null
                                        ? quote.company.name
                                        : quote.preventivas_company
                                          .company_name}
                                      {' '}
                                      <span className="text-success">
                                        (
                                        {
                                            quotationTypes.find(
                                              (item) => item.id === quote.quote_type,
                                            ).name
                                          }
                                        )
                                      </span>
                                    </h5>
                                    <h5 className="fw-bold">
                                      {parseFloat(quote.amount).toFixed(2)}
                                      {' '}
                                      €
                                    </h5>
                                  </div>
                                </li>
                              ))
                              : ''}
                          </React.Fragment>
                        ))}
                      <hr />
                      <li>
                        <div className="d-flex justify-content-end align-items-center">
                          <h5 className="me-5 fw-bold">Totale:</h5>
                          <h5 className="fw-bold">
                            {' '}
                            {getTotal()}
                            {' '}
                            €
                          </h5>
                        </div>
                      </li>
                    </ul>
                    <div className="d-flex justify-content-end mt-3">
                      <button
                        className="btn btn-success btn-lg rounded-4 px-5 fw-bold"
                        onClick={() => handleRequestPurchase()}
                      >
                        Aquista
                      </button>
                    </div>
                  </div>
                )}
              </GuaranteesDefaultStateStyles>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <EstimateContainer>
                <div className="d-flex gap-2 justify-content-end ">
                  <div>
                    {/* {localStorage.getItem('quoteNewResult') === 'done' && (
                      <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                        <button className="btn btn-success px-2 py-1">Tutorial</button>
                      </div>
                    )} */}
                  </div>
                </div>
              </EstimateContainer>
            </div>
          </div>
          <RiskContainer className="mb-3 pb-3 result-home-third-step">
            {quotationTypes.map((type, index) => (
              <React.Fragment key={index}>
                {quotations && quotations[type.id] && (
                  <HouseModalButtons
                    insuranceRequest={insuranceRequest}
                    processingQuote={processingQuote}
                    type={type}
                    removeItemFromCart={removeItemFromCart}
                    riskQuotations={riskQuotations}
                  />
                )}
              </React.Fragment>
            ))}
          </RiskContainer>
        </div>
        {quotationTypes.map((type, index) => (
          <HouseModal
            insuranceRequest={insuranceRequest}
            type={type}
            key={index}
            addingQuote={addingQuote}
            quotations={quotations}
            setOpenModal={setOpenModal}
            openModal={openModal}
            requestToken={requestToken}
            refresh={refresh}
            selectQuotation={selectQuotation}
          />
        ))}
      </HomeQuotesStyles>
    </>
  );
}

export default HomeResultPage;

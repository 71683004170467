import React from 'react';
import ProcessingQuoteSelectedAuto from './ProcessingQuoteSelectedAuto';
// import TutelaSelectedAuto from './TutelaSelectedAuto' //TODO REMOVE COMPONENT ENTIRELY IF NOT NEEDED
// import AssistenzaStradaleSelectedAuto from './AssistenzaStradaleSelectedAuto' //TODO REMOVE COMPONENT ENTIRELY IF NOT NEEDED
// import InfortuniConducenteSelectedAuto from './InfortuniConducenteSelectedAuto' //TODO REMOVE COMPONENT ENTIRELY IF NOT NEEDED
// import CristalliSelectedAuto from './CristalliSelectedAuto' //TODO REMOVE COMPONENT ENTIRELY IF NOT NEEDED
// import RivalsaSelectedAuto from './RivalsaSelectedAuto' //TODO REMOVE COMPONENT ENTIRELY IF NOT NEEDED
import GlobalAssistanceCard from '../Cards/GlobalAssistanceCard';
// import QuotationSelectedCommercial from './QuotationSelectedCommercial' //TODO REMOVE COMPONENT ENTIRELY IF NOT NEEDED
// import FurtoIncendioSelectedAuto from './FurtoIncendioSelectedAuto' //TODO REMOVE COMPONENT ENTIRELY IF NOT NEEDED
// import CollisioneSelectedAuto from './CollisioneSelectedAuto' //TODO REMOVE COMPONENT ENTIRELY IF NOT NEEDED
import RiskCardSelected from '../Cards/RiskCardSelected';

function SelectedQuotesCards({
  processingQuote,
  type,
  refresh,
  requestInfo,
  riskQuotations,
}) {
  return (
    <>
      {processingQuote !== null && type.id === 'res_civile' && (
        <ProcessingQuoteSelectedAuto
          processingQuote={processingQuote}
          requestInfo={requestInfo}
          refresh={refresh}
        />
      )}

      {processingQuote === null && type.id === 'res_civile' && (
        <div
          data-bs-toggle="modal"
          data-bs-target={`#${type.id}`}
          style={{ cursor: 'pointer' }}
          className="alert alert-secondary"
        >
          Seleziona Quotazione!
        </div>
      )}

      {processingQuote !== null
        && type.id === 'res_civile'
        && processingQuote.company_id == 16 && (
          <div className="col-12 mb-2">
            <GlobalAssistanceCard
              requestInfo={requestInfo}
              quotation={processingQuote}
            />
          </div>
      )}

      {riskQuotations
        && riskQuotations[type.id] !== undefined
        && riskQuotations[type.id].map((quote, idx) => (
          <React.Fragment key={idx}>
            {processingQuote !== null
              && processingQuote.company_id !== 2
              && type.id === quote.quote_type && (
                <div className="col-12 mb-2">
                  <RiskCardSelected quote={quote} type={type} />
                </div>
            )}
          </React.Fragment>
        ))}

      {riskQuotations
        && riskQuotations[type.id] === undefined
        && type.id !== 'res_civile'
        && processingQuote !== null && (
          <div
            data-bs-toggle="modal"
            data-bs-target={`#${type.id}`}
            style={{ cursor: 'pointer' }}
            className="alert alert-secondary"
          >
            Seleziona Quotazione!
          </div>
      )}
    </>
  );
}

export default SelectedQuotesCards;

import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  auto: {
    loading: true,
    quote: null,
    insuranceRequest: null,
    quoteStatus: 1,
    uploadingIdCard: false,
    uploadingVehicleCard: false,
    uploadingLibrettoVeicoloBersani: false,
    uploadingStatoDiFamiglia: false,
    uploadingIdCardFamigliare: false,
    uploadingVisuraCamerale: false,
    signMethod: null,
    uploadedFirstDocs: {
      id_card: 0,
      vehicle_card: 0,
      libretto_veicolo_bersani: 0,
      stato_di_famiglia: 0,
      id_card_famigliare: 0,
      visura_camerale: 0,
    },
    backofficeUsers: [],
    uploadingOptionalDocumentRequestId: null,
    with_compensation: 0,
    rc_quotation: null,
    tutela_quotation: null,
    assistenza_quotation: null,
    cristalli_quotation: null,
    infortuni_conducente_quotation: null,
    rivalsa_quotation: null,
    collisione_quotation: null,
    nonSignableDocs: [],
    requestedOptionalDocumentsNonSignable: [],
    signableDocs: [],
    requestedOptionalDocumentsSignable: [],
    furto_incendio_quotation: null,
    risk_quotations: null,
  },
  auto_completed: {
    loading: true,
    quote: null,
    error: null,
    insuranceRequest: null,
    quoteStatus: 1,
  },
  autocarro: {
    loading: true,
    quote: null,
    insuranceRequest: null,
    quoteStatus: 1,
    uploadingIdCard: false,
    uploadingVehicleCard: false,
    uploadingLibrettoVeicoloBersani: false,
    uploadingStatoDiFamiglia: false,
    uploadingIdCardFamigliare: false,
    uploadingVisuraCamerale: false,
    signMethod: null,
    uploadedFirstDocs: {
      id_card: 0,
      vehicle_card: 0,
      libretto_veicolo_bersani: 0,
      stato_di_famiglia: 0,
      id_card_famigliare: 0,
      visura_camerale: 0,
    },
    backofficeUsers: [],
    uploadingOptionalDocumentRequestId: null,
    with_compensation: 0,
    rc_quotation: null,
    tutela_quotation: null,
    assistenza_quotation: null,
    cristalli_quotation: null,
    infortuni_conducente_quotation: null,
    rivalsa_quotation: null,
    nonSignableDocs: [],
    requestedOptionalDocumentsNonSignable: [],
    signableDocs: [],
    requestedOptionalDocumentsSignable: [],
  },
  moto: {
    loading: true,
    quote: null,
    insuranceRequest: null,
    quoteStatus: 1,
    uploadingIdCard: false,
    uploadingVehicleCard: false,
    uploadingLibrettoVeicoloBersani: false,
    uploadingStatoDiFamiglia: false,
    uploadingIdCardFamigliare: false,
    uploadingVisuraCamerale: false,
    signMethod: null,
    uploadedFirstDocs: {
      id_card: 0,
      vehicle_card: 0,
      libretto_veicolo_bersani: 0,
      stato_di_famiglia: 0,
      id_card_famigliare: 0,
      visura_camerale: 0,
    },
    backofficeUsers: [],
    uploadingOptionalDocumentRequestId: null,
    with_compensation: 0,
    rc_quotation: null,
    tutela_quotation: null,
    nonSignableDocs: [],
    requestedOptionalDocumentsNonSignable: [],
    signableDocs: [],
    requestedOptionalDocumentsSignable: [],
  },
  moto_completed: {
    loading: true,
    quote: null,
    error: null,
    insurance_request: null,
    quoteStatus: 1,
  },
  profession: {
    loading: true,
    quote: null,
    insuranceRequest: null,
    quoteStatus: 1,
    uploadingIdCard: false,
    uploadingModuloDiProposta: false,
    uploadingVisuraCamerale: false,
    signMethod: null,
    uploadedFirstDocs: {
      id_card: 0,
      modulo_di_proposta: 0,
      visura_camerale: 0,
    },
    backofficeUsers: [],
    with_compensation: 0,
    rc_quotation: null,
    tutela_quotation: null,
    nonSignableDocs: [],
    requestedOptionalDocumentsNonSignable: [],
    signableDocs: [],
    requestedOptionalDocumentsSignable: [],
    risk_quotations: null,
  },
  profession_completed: {
    loading: true,
    quote: null,
    error: null,
    insurance_request: null,
    quoteStatus: 1,
  },
  infortuni: {
    loading: true,
    quote: null,
    insuranceRequest: null,
    quoteStatus: 1,
    uploadingIdCard: false,
    uploadingVisuraCamerale: false,
    signMethod: null,
    uploadedFirstDocs: {
      id_card: 0,
      visura_camerale: 0,
    },
    backofficeUsers: [],
    with_compensation: 0,
  },
  infodrive: {
    loading: true,
    quote: null,
    insuranceRequest: null,
    infodriveQuotationData: null,
    quoteStatus: 4,
    backofficeUsers: [],
    with_compensation: 0,
  },
  travel: {
    loading: true,
    quotation: null,
    insuranceRequest: null,
    quoteStatus: 4,
    backofficeUsers: [],
    rc_quotation: null,
    tutela_quotation: null,
    nonSignableDocs: [],
    requestedOptionalDocumentsNonSignable: [],
  },
  travel_completed: {
    loading: true,
    quote: null,
    error: null,
    insurance_request: null,
    quoteStatus: 1,
  },
  commercial: {
    loading: true,
    quote: null,
    insuranceRequest: null,
    quoteStatus: 1,
    uploadingIdCard: false,
    uploadingVehicleCard: false,
    uploadingLibrettoVeicoloBersani: false,
    uploadingStatoDiFamiglia: false,
    uploadingIdCardFamigliare: false,
    uploadingVisuraCamerale: false,
    signMethod: null,
    uploadedFirstDocs: {
      id_card: 0,
      vehicle_card: 0,
      libretto_veicolo_bersani: 0,
      stato_di_famiglia: 0,
      id_card_famigliare: 0,
      visura_camerale: 0,
    },
    backofficeUsers: [],
    uploadingOptionalDocumentRequestId: null,
    with_compensation: 0,
    rc_quotation: null,
    nonSignableDocs: [],
    requestedOptionalDocumentsNonSignable: [],
    signableDocs: [],
    requestedOptionalDocumentsSignable: [],
    risk_quotations: null,
  },
  commercial_completed: {
    loading: true,
    quote: null,
    error: null,
    insuranceRequest: null,
    quoteStatus: 1,
  },
  tutela_legale: {
    loading: true,
    quote: null,
    insuranceRequest: null,
    quoteStatus: 1,
    uploadingIdCard: false,
    uploadingVisuraCamerale: false,
    signMethod: null,
    uploadedFirstDocs: {
      id_card: 0,
      visura_camerale: 0,
    },
    backofficeUsers: [],
    uploadingOptionalDocumentRequestId: null,
    with_compensation: 0,
    rc_quotation: null,
    nonSignableDocs: [],
    requestedOptionalDocumentsNonSignable: [],
    signableDocs: [],
    requestedOptionalDocumentsSignable: [],
    risk_quotations: null,
  },
  tutela_legale_completed: {
    loading: true,
    quote: null,
    error: null,
    insurance_request: null,
    quoteStatus: 1,
  },
  house: {
    loading: true,
    quote: null,
    insuranceRequest: null,
    quoteStatus: 1,
    uploadingIdCard: false,
    uploadingVisuraCamerale: false,
    signMethod: null,
    uploadedFirstDocs: {
      id_card: 0,
    },
    backofficeUsers: [],
    uploadingOptionalDocumentRequestId: null,
    with_compensation: 0,
    rc_quotation: null,
    nonSignableDocs: [],
    requestedOptionalDocumentsNonSignable: [],
    signableDocs: [],
    requestedOptionalDocumentsSignable: [],
    risk_quotations: null,
  },
  house_completed: {
    loading: true,
    quote: null,
    error: null,
    insurance_request: null,
    quoteStatus: 1,
    risk_quotations: null,
  },
};

export const purchasesSlice = createSlice({
  name: 'purchases',
  initialState,
  reducers: {
    autoUpdateState: (state, { payload }) => {
      state.auto = { ...state.auto, ...payload };
    },
    autoResetState: (state) => {
      state.auto = { ...initialState.auto };
    },
    autoCompletedUpdateState: (state, { payload }) => {
      state.auto_completed = { ...state.auto_completed, ...payload };
    },
    autoCompletedResetState: (state) => {
      state.auto_completed = { ...initialState.auto_completed };
    },
    autocarroUpdateState: (state, { payload }) => {
      state.autocarro = { ...state.autocarro, ...payload };
    },
    autocarroResetState: (state) => {
      state.autocarro = { ...initialState.autocarro };
    },
    motoUpdateState: (state, { payload }) => {
      state.moto = { ...state.moto, ...payload };
    },
    motoResetState: (state) => {
      state.moto = { ...initialState.moto };
    },

    professionUpdateState: (state, { payload }) => {
      state.profession = { ...state.profession, ...payload };
    },
    professionResetState: (state) => {
      state.profession = { ...initialState.profession };
    },
    professionCompletedUpdateState: (state, { payload }) => {
      state.profession_completed = { ...state.profession_completed, ...payload };
    },
    professionCompletedResetState: (state) => {
      state.profession_completed = { ...initialState.profession_completed };
    },
    travelCompletedUpdateState: (state, { payload }) => {
      state.travel_completed = { ...state.travel_completed, ...payload };
    },
    travelCompletedResetState: (state) => {
      state.travel_completed = { ...initialState.travel_completed };
    },
    infortuniUpdateState: (state, { payload }) => {
      state.infortuni = { ...state.infortuni, ...payload };
    },
    infortuniResetState: (state) => {
      state.infortuni = { ...initialState.infortuni };
    },
    infodriveUpdateState: (state, { payload }) => {
      state.infodrive = { ...state.infodrive, ...payload };
    },
    infodriveResetState: (state) => {
      state.infodrive = { ...initialState.infodrive };
    },
    travelUpdateState: (state, { payload }) => {
      state.travel = { ...state.travel, ...payload };
    },
    travelResetState: (state) => {
      state.travel = { ...initialState.travel };
    },
    commercialUpdateState: (state, { payload }) => {
      state.commercial = { ...state.commercial, ...payload };
    },
    commercialResetState: (state) => {
      state.commercial = { ...initialState.commercial };
    },
    tutelaLegaleUpdateState: (state, { payload }) => {
      state.tutela_legale = { ...state.tutela_legale, ...payload };
    },
    tutelaLegaleResetState: (state) => {
      state.tutela_legale = { ...initialState.tutela_legale };
    },
    tutelaLegaleCompletedUpdateState: (state, { payload }) => {
      state.tutela_legale_completed = { ...state.tutela_legale_completed, ...payload };
    },
    tutelaLegaleCompletedResetState: (state) => {
      state.tutela_legale_completed = { ...initialState.tutela_legale_completed };
    },
    motoCompletedUpdateState: (state, { payload }) => {
      state.moto_completed = { ...state.moto_completed, ...payload };
    },
    motoCompletedResetState: (state) => {
      state.moto_completed = { ...initialState.moto_completed };
    },
    commercialCompletedUpdateState: (state, { payload }) => {
      state.commercial_completed = { ...state.commercial_completed, ...payload };
    },
    commercialCompletedResetState: (state) => {
      state.commercial_completed = { ...initialState.commercial_completed };
    },
    houseUpdateState: (state, { payload }) => {
      state.house = { ...state.house, ...payload };
    },
    houseResetState: (state) => {
      state.house = { ...initialState.house };
    },
    houseCompletedUpdateState: (state, { payload }) => {
      state.house_completed = { ...state.house_completed, ...payload };
    },
    houseCompletedResetState: (state) => {
      state.house_completed = { ...initialState.house_completed };
    },
  },
});

export default callerCreator(purchasesSlice.actions);

import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
// import Joyride from 'react-joyride';
import { TravelFormFlowStyles, TravelFormStyles } from './TravelFormFlow.styles';
import CenteredPageTitle from '../../common/components/CenteredPageTitle';
import { travelSchema } from './TravelFormFlow.validation';
import { modifyRequestData, updateActiveCustomer } from '../../services/axios-client/axeCommons';
import { useDebouncedEffect } from '../../common/utils/useDebouncedEffect';
import { FormInputCheckbox } from '../../common/formInputs/FormInputCheckbox';
import generateFiscalCode from '../../common/helpers/generateFiscalCode';
import { FormInputText } from '../../common/formInputs/FormInputText';
import ActiveClientModal from '../../common/components/elements/ActiveClientModal';
import { FormInputRadio } from '../../common/formInputs/FormInputRadio';
import { flagResponse, genders } from '../../common/constants';
import { FormInputDate } from '../../common/formInputs/FormInputDate';
import PageLoading from '../../common/components/elements/PageLoading';
import {
  resetTravelForm, travelGetCustomerData, travelGetInitialData, travelSearchActiveClient, travelSubmitAnswers, travelUpdateFormData,
} from '../../features/forms/formsActions';
import InsuredPerson from './InsuredPerson';
import convertToItalianDate from '../../common/helpers/convertToItalianDate';
import InputPrivacy from '../../common/components/inputs/InputPrivacy';
import { generalAlertError, generalAlertErrorToast } from '../../common/utils/alerts';
import StorageService from '../../services/StorageService';
import FormReactSelectInputBirthPlace from '../../common/formInputs/FormReactSelectInputBirthPlace';
import FormReactSelectStates from '../../common/formInputs/FormReactSelectStates';
import { FormInputAddressComplete } from '../../common/formInputs/FormInputAddressComplete';
// import { useTourContext } from '../../context/useTourContext';
import { CenteredDivTitle } from '../../common/components/CenteredPageTitle.styles';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackTravelForm } from '../../common/utils/joyrideCallbacks';
// import { travelFormFlowSteps } from '../../common/utils/joyrideSteps';

const Storage = new StorageService();
function TravelFormFlow() {
  const navigate = useNavigate();
  const StoredData = Storage.getTravelData();
  const skipTotalPeopleRef = useRef(false);
  const state = useSelector((store) => store.forms.travel);
  const {
    loading,
    states,
    municipalities,
    formData,
    dataPrivacy,
    searchResults,
    selectedClientOldData,
    selectedClient,
    selectedClientId,
    travel_states,
  } = state;
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    trigger,
    getValues,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(travelSchema),
    shouldFocusError: true,
    defaultValues: StoredData || {
      product: 'travel',
      source: 'greenia.it',
      policy_effective_date: '',
      end_date: '',
      privacies: [],
      is_family: null,
      name: '',
      surname: '',
      email: '',
      fiscal_code: '',
      residence_province_code: '',
      address: '',
      house_number: '',
      postal_code: '',
      phone: '',
      gender: '',
      date_of_birth: '',
      country_of_birth_code: 'Z000',
      province_of_birth_code: null,
      commune_of_birth_code: null,
      born_abroad: false,
      residence_commune_code: '',
      active_customer_id: null,
      total_people: 0,
      insured_persons: [],
      existing_customer: false,
      travel_country_code: '',
      travel_alone: null,
      is_contractor_included: null,
    },
  });

  const location = useLocation();
  const [searchClient, setSearchClient] = useState('');
  const skipCheckGenderRef = useRef(null);

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('newQuoteTravel') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('newQuoteTravel')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('newQuoteTravel');
  //   setState({ run: true, tourActive: true });
  // };

  const closeClientModal = () => {
    setSearchClient('');
    travelUpdateFormData({
      selectedClient: null,
      selectedClientOldData: null,
      selectedClientId: null,
      searchResults: [],
    });
  };

  const formDataFlow = watch();

  const updateFormData = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  const getCustomerData = async (customerId) => {
    travelGetCustomerData(customerId);
  };

  useEffect(() => {
    if (location.state !== null && location.state.activeCustomerId !== undefined) {
      getCustomerData(location.state.activeCustomerId);
    }
  }, []);

  useEffect(() => {
    if (skipCheckGenderRef.current === true) {
      skipCheckGenderRef.current = false;
      return;
    }

    const triggerValidation = {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    };

    if (['M', 'F'].includes(formDataFlow.gender) && formDataFlow.date_of_birth === null) {
      setValue('date_of_birth', '', triggerValidation);
      setValue('country_of_birth_code', 'Z000', triggerValidation);
      setValue('province_of_birth_code', null, triggerValidation);
      setValue('commune_of_birth_code', null, triggerValidation);
      setValue('born_abroad', false, triggerValidation);
      setValue('name', '', triggerValidation);
      setValue('surname', '', triggerValidation);
    }
  }, [formDataFlow.gender, formDataFlow.date_of_birth]);

  useEffect(() => {
    const values = getValues();

    if (values !== undefined) {
      const objectKeys = Object.keys(values);

      objectKeys.forEach((key) => {
        if (values[key] !== '' && values[key] !== null) {
          setValue(key, values[key], {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      });
    }
  }, []);

  const getFiscalCode = (birthDate) => {
    const d = birthDate.split('-');
    const fData = {
      name: formDataFlow.name,
      surname: formDataFlow.surname,
      gender: formDataFlow.gender,
      day: d[2],
      month: d[1],
      year: d[0],
    };
    if (formDataFlow.born_abroad) {
      fData.birthplace = states.filter(
        (s) => s.state_code === formDataFlow.country_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = 'EE';
    } else {
      fData.birthplace = municipalities.filter(
        (municipality) => municipality.cadastral_code === formDataFlow.commune_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = formDataFlow.province_of_birth_code;
    }
    return generateFiscalCode(fData);
  };

  const validateDataPrivacy = () => {
    let { error, message } = '';

    dataPrivacy.forEach((privacy) => {
      if (privacy.required === 1) {
        const formDataArray = { ...formDataFlow };
        const index = formDataArray.privacies.indexOf(privacy.id);

        if (index < 0) {
          error = true;
          message = 'Tutti i campi sono obbligatori! Tutta la privacy con la stella deve essere controllata';
        }
      }
    });
    return { error, message };
  };

  const confirmActiveClient = async (selectedClient) => {
    if (
      JSON.stringify(selectedClientOldData) !== JSON.stringify(selectedClient)
    ) {
      await updateActiveCustomer(selectedClientId, {
        ...selectedClient,
        date_of_birth: selectedClient.gender !== 'G' ? convertToItalianDate(selectedClient.date_of_birth) : null,
      });
    }

    updateFormData({
      born_abroad:
        selectedClient.born_abroad !== null
          ? selectedClient.born_abroad
          : false,
      fiscal_code: selectedClient.fiscal_code,
      gender: selectedClient.gender,
      house_number: selectedClient.house_number,
      name: selectedClient.name,
      phone: selectedClient.phone,
      postal_code: selectedClient.postal_code,
      surname: selectedClient.surname,
      address: selectedClient.address,
      date_of_birth: selectedClient.date_of_birth,
      email: selectedClient.email,
      province_of_birth_code: selectedClient.province_of_birth_code,
      residence_commune_code: selectedClient.residence_city_code, // residence_city_code
      residence_province_code: selectedClient.residence_province_code,
      country_of_birth_code: selectedClient.country_of_birth_code,
      commune_of_birth_code: selectedClient.city_of_birth_code, // city_of_birth_code,
      active_customer_id: selectedClientId,
    });

    setSearchClient('');
    travelUpdateFormData({
      selectedClient: null,
      selectedClientOldData: null,
      selectedClientId: null,
      searchResults: [],
    });
  };

  const onSearchChange = (value) => {
    setSearchClient(value);

    if (value.length === 0) {
      travelUpdateFormData({
        searchResults: [],
      });
    }
  };

  useEffect(() => {
    travelGetInitialData();

    return () => {
      resetTravelForm();
    };
  }, []);

  const onSubmit = (formDataFlow) => {
    const validation = validateDataPrivacy();
    const { error, message } = validation;

    if (error) {
      generalAlertError(message);
      return;
    }

    const _formDataFlow = { ...formDataFlow };

    if (_formDataFlow.born_abroad && _formDataFlow.country_of_birth_code === 'Z000') {
      window.scrollTo(0, 0);
      generalAlertErrorToast('Non puoi selezionare Italia come stato estero!');
      return;
    }
    if (_formDataFlow.born_abroad && (_formDataFlow.country_of_birth_code === null || _formDataFlow.country_of_birth_code === '' || _formDataFlow.country_of_birth_code == undefined)) {
      window.scrollTo(0, 0);
      generalAlertErrorToast('Devi selezionare uno stato estero!');
      return;
    }
    if (formDataFlow.date_of_birth !== '' && formDataFlow.date_of_birth !== null) {
      _formDataFlow.date_of_birth = moment(formDataFlow.date_of_birth).format('YYYY-MM-DD');
    } else {
      _formDataFlow.date_of_birth = null;
    }
    _formDataFlow.policy_effective_date = moment(formDataFlow.policy_effective_date).format('YYYY-MM-DD');
    _formDataFlow.fiscal_code = formDataFlow.gender !== 'G' ? getFiscalCode(formDataFlow.date_of_birth) : null;

    if (formDataFlow.total_people < 2) {
      _formDataFlow.is_family = false;
    }

    if (formDataFlow.travel_alone === 1) {
      _formDataFlow.is_contractor_included = 1;
    }

    delete _formDataFlow.total_people;
    delete _formDataFlow.existing_customer;

    travelSubmitAnswers(_formDataFlow, navigate);
  };

  const privacyItemChangeHandler = (value, item) => {
    if (!value.target.checked) {
      const newArray = [...formDataFlow.privacies];
      const index = newArray.indexOf(item.id);
      newArray.splice(index, 1);
      updateFormData({
        privacies: newArray,
      });
      return;
    }
    const answersNewArray = [...formDataFlow.privacies];
    answersNewArray.push(item.id);
    updateFormData({
      privacies: answersNewArray,
    });
  };

  const searchActiveClient = (searchClient) => () => {
    if (searchClient.length === 0) return;
    travelSearchActiveClient(searchClient);
  };

  useDebouncedEffect(searchActiveClient(searchClient), [searchClient], 400);

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const isValidInsuredPerson = (index) => (name, field) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (typeof touchedFields.insured_persons === 'boolean') {
      return false;
    }

    if (touchedFields.insured_persons[index] === undefined) {
      return false;
    }

    if (touchedFields.insured_persons[index][field] === undefined) {
      return false;
    }

    return true;
  };

  const handleInsuredPersons = () => {
    const newVal = parseInt(formDataFlow.total_people || 0);
    const newArr = [];
    for (let i = 0; i < newVal; i++) {
      newArr.push({
        name: '',
        surname: '',
        birth_date: '',
      });
    }

    setValue('insured_persons', [...formDataFlow.insured_persons, ...newArr]);

    if (newVal < formDataFlow.insured_persons.length) {
      for (let i = formDataFlow.insured_persons.length; i > newVal; i--) {
        formDataFlow.insured_persons.pop();
        setValue('insured_persons', formDataFlow.insured_persons);
      }
    }
  };

  const handleInsuredPersonsChange = (name, value) => {
    setValue(name, value, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  useEffect(() => {
    const callAsync = async () => {
      if (location.state !== null && location.state.requestToken !== undefined) {
        skipCheckGenderRef.current = true;
        const res = await modifyRequestData(location.state.requestToken);
        skipTotalPeopleRef.current = true;
        const travelAlone = res.data.data.formDataFlow.insured_persons.length === 0 ? '1' : '0';
        updateFormData({
          ...res.data.data.formDataFlow,
          is_family: res.data.data.formDataFlow.is_family === false ? '0' : '1',
          travel_alone: travelAlone,
        });
        // checkIfTravelingStateIsEurope(res.data.data.formDataFlow.travel_country_code)
      }
    };

    callAsync();
  }, []);

  useEffect(() => {
    if (skipTotalPeopleRef.current) {
      skipTotalPeopleRef.current = false;
      return;
    }
    handleInsuredPersons();
  }, [formDataFlow.total_people]);

  useEffect(() => {
    Storage.setTravelData(formDataFlow);
  }, [formDataFlow]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <TravelFormFlowStyles>
      {/* <Joyride
        steps={travelFormFlowSteps}
        continuous
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,
          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackTravelForm(data, travelFormFlowSteps, setState)}
        showBeacon={showBeacon}
        showSkipButton
        showProgress
        disableScrolling
      /> */}

      <TravelFormStyles onSubmit={handleSubmit(onSubmit)} className="w-50 mx-auto">
        <CenteredDivTitle className="d-flex align-items-center justify-content-center gap-3">
          <CenteredPageTitle title="Completa i dati" />
        </CenteredDivTitle>
        {/* {localStorage.getItem('newQuoteTravel') === 'done' && (
          <div style={{ cursor: 'pointer', top: '8%' }} onClick={() => fireNewQuoteTour()} className="position-fixed end-0 mt-3 me-3">
            <button className="btn btn-success px-2 py-1">Tutorial</button>
          </div>
        )} */}

        <div className="travel-tutorial">
          {formDataFlow.gender !== 'G' ? (
            <div>
              <FormInputCheckbox
                registration={register('existing_customer')}
                label=" Cliente Esistente?"
              />
              {formDataFlow.existing_customer && (
                <div className="d-flex align-items-start justify-content-center gap-2">
                  <div className="position-relative">
                    <FormInputText
                      placeholder="Nome cliente..."
                      onChange={(value) => onSearchChange(value)}
                      value={searchClient}
                    />
                    {searchResults.length > 0 && (
                      <div
                        className="position-absolute"
                        style={{
                          top: '80%', left: '0%', right: '0%', zIndex: 50,
                        }}
                      >
                        <ul className="list-group">
                          {searchResults.map((customer, index) => (
                            <li
                              className="list-group-item list-group-item-dark"
                              key={index}
                              style={{ cursor: 'pointer' }}
                              onClick={() => getCustomerData(customer.id)}
                            >
                              {customer.name.toUpperCase() + (customer.fiscal_code !== null ? ` - (${customer.fiscal_code})` : '')}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <button
                    className="btn btn-questionnaire py-1 px-2 "
                    style={{
                      marginTop: 10,
                      display: 'block',
                      width: '150px',
                      border: '3px solid',
                    }}
                  >
                    Cerca cliente
                  </button>
                </div>
              )}

              {selectedClient !== null ? (
                <ActiveClientModal
                  closeClientModal={closeClientModal}
                  municipalities={municipalities}
                  states={states}
                  formData={formData}
                  client={selectedClient}
                  genders={genders}
                  confirmActiveClient={confirmActiveClient}
                />
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}

          <div className="form-container ">
            <FormInputRadio
              options={genders.filter((g) => g.value !== 'G')}
              label="Il contraente è"
              name="gender"
              registration={register('gender')}
              error={errors.gender?.message}
            />

            <div>
              <FormInputText
                placeholder="Nome"
                label="Nome"
                registration={register('name')}
                error={errors.name?.message}
                valid={isValid('name')}
              />
              <FormInputText
                placeholder="Cognome"
                label="Cognome"
                registration={register('surname')}
                error={errors.surname?.message}
                valid={isValid('surname')}
              />
              <FormInputDate
                label="Data di nascita"
                registration={register('date_of_birth')}
                minDate="1935-01-01"
                maxDate="2021-01-01"
                error={errors.date_of_birth?.message}
                valid={isValid('date_of_birth')}
              />

              <FormReactSelectInputBirthPlace
                states={states}
                options={municipalities}
                label="Luogo di nascita"
                name="commune_of_birth_code"
                values={{
                  commune_of_birth_code: formDataFlow.commune_of_birth_code,
                  province_of_birth_code: formDataFlow.province_of_birth_code,
                  born_abroad: formDataFlow.born_abroad,
                  country_of_birth_code: formDataFlow.country_of_birth_code,
                }}
                onChange={(value) => updateFormData({
                  commune_of_birth_code: value.commune_of_birth_code,
                  province_of_birth_code: value.province_of_birth_code,
                  born_abroad: value.born_abroad,
                  country_of_birth_code: value.country_of_birth_code,
                })}
                valid={isValid('commune_of_birth_code')}
                error={errors.commune_of_birth_code?.message}
                error_country_of_birth_code={errors.country_of_birth_code?.message}
                valid_country_of_birth_code={isValid('country_of_birth_code')}
              />
            </div>
            <FormInputText
              registration={register('email')}
              label="E-mail"
              error={errors.email?.message}
              valid={isValid('email')}
            />
            <FormInputText
              registration={register('phone')}
              label="Phone"
              paragraph="Numeri di rete fissa non accettati dalle aziende"
              error={errors.phone?.message}
              valid={isValid('phone')}
            />
            <FormInputAddressComplete
              municipalities={municipalities}
              formData={formDataFlow}
              isValid={isValid}
              errors={errors}
              register={register}
              updateFormData={updateFormData}
              addressId="autocomplete-address-contractor"
            />

            <FormInputRadio
              options={flagResponse}
              label="Viaggi da solo?"
              name="travel_alone"
              registration={register('travel_alone')}
              error={errors.travel_alone?.message}
            />

            {formDataFlow.travel_alone == 0 && (
              <FormInputRadio
                options={flagResponse}
                label="Il contraente viaggerà?"
                name="is_contractor_included"
                registration={register('is_contractor_included')}
                error={errors.is_contractor_included?.message}
              />
            )}

            {formDataFlow.travel_alone == 0 && (
              <FormInputText
                min={0}
                label="Numero persone incluse"
                paragraph="Inscerisci un numero di persone in qui viaggerai"
                error={errors.total_people?.message}
                valid={isValid('total_people')}
                type="number"
                inputId="input-number"
                value={formDataFlow.total_people}
                onChange={(value) => {
                  updateFormData({
                    total_people: value,
                  });
                }}
              />
            )}

            {formDataFlow.insured_persons.map((field, index) => (
              <InsuredPerson
                key={index}
                values={field}
                name={`insured_persons.${index}.name`}
                surname={`insured_persons.${index}.surname`}
                birth_date={`insured_persons.${index}.birth_date`}
                error_name={errors.insured_persons && errors.insured_persons[index] ? errors.insured_persons[index].name?.message : undefined}
                error_surname={errors.insured_persons && errors.insured_persons[index] ? errors.insured_persons[index].surname?.message : undefined}
                error_birth_date={errors.insured_persons && errors.insured_persons[index] ? errors.insured_persons[index].birth_date?.message : undefined}
                isValid={isValidInsuredPerson(index)}
                onChange={handleInsuredPersonsChange}
              />
            ))}

            {formDataFlow.insured_persons.length >= 2 && (
              <FormInputRadio
                label="Le persone incluse sono in nucleo famigliare?"
                options={flagResponse}
                name="is_family"
                registration={register('is_family')}
                error={errors.is_family?.message}
              />
            )}

            <FormReactSelectStates
              options={travel_states}
              error={errors.travel_country_code?.message}
              valid={isValid('travel_country_code')}
              label="Dove vuoi viaggiare!"
              onChange={(item) => updateFormData({
                travel_country_code: item.travel_country_code,
              })}
              values={{
                travel_country_code: formDataFlow.travel_country_code,
              }}
            />

            {/* {isEurope === false && <FormInputRadio
            label="Stati Uniti e Canada Inclusa?"
            options={flagResponse}
            name="include_usa_canda"
            registration={register('include_usa_canda')}
            error={errors.include_usa_canda?.message}
          />} */}

            <FormInputDate
              label="Data partenza (dalle ore 24 della data selezionata)"
              registration={register('policy_effective_date')}
              minDate={moment().format('YYYY-MM-DD')}
              error={errors.policy_effective_date?.message}
              valid={isValid('policy_effective_date')}
            />

            <FormInputDate
              label="Data ritorno (dalle ore 24 della data selezionata)"
              registration={register('end_date')}
              minDate={formDataFlow.policy_effective_date ? formDataFlow.policy_effective_date : moment().format('YYYY-MM-DD')}
              error={errors.end_date?.message}
              valid={isValid('end_date')}
            />
          </div>
        </div>
        <div className="second-travel-step">
          {dataPrivacy.map((privacy, i) => (
            <InputPrivacy
              required={privacy.required}
              label={privacy.content}
              name={privacy.type}
              id={privacy.type}
              key={i}
              checked={formDataFlow?.privacies?.includes(privacy.id)}
              onChange={(value) => privacyItemChangeHandler(value, privacy)}
            />
          ))}
        </div>

        <div className="footer-buttons button-travel-step">
          <div className="d-flex justify-content-between">
            <button type="submit" className="btn btn-questionnaire">
              Vai a preventivo
            </button>
          </div>
        </div>
      </TravelFormStyles>
    </TravelFormFlowStyles>
  );
}

export default TravelFormFlow;

import React from 'react';
import HomeCardResult from '../Cards/HomeCardResult';

function ProcessingQuoteSelectedHome({ quote, insuranceRequest }) {
  return (
    <>
      <div className="col-12 mb-2">
        <HomeCardResult quote={quote} insuranceRequest={insuranceRequest} />
      </div>
    </>
  );
}

export default ProcessingQuoteSelectedHome;

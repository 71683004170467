import React, { useState } from 'react';
import { ProfessionCardStyles } from './ProfessionCard.styles';
import {
  AquistaButton,
  AquistaButtonsContainer,
  CompanyInfoElements,
  CompanyInformationContainer,
  LeftCol,
  LogoContainer,
  LogoPriceContainer,
  Price,
  PriceContainer,
  QuoteInformationContainer,
  QuoteInformationItem,
  RightCol,
} from './AdriaticCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';
import { SavedButton } from './common/Buttons.styles';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';
import GlobalAssistencaStradaleModal from '../Modal/GlobalAssistencaStradaleModal';
import GlobalAssistanceTutelaModal from '../Modal/GlobalAssistanceTutelaModal';
import GlobalAssistanceInfortuniModal from '../Modal/GlobalAssistanceInfortuniModal';
import GlobalAssistanceCristalliModal from '../Modal/GlobalAssistanceCristalliModal';
import EuropeAssistencaStradaleModal from '../Modal/EuropeAssistencaStradaleModal';
import EuropeAssistanceTutelaModal from '../Modal/EuropeAssistanceTutelaModal';
import EuropeAssistanceCristalliModal from '../Modal/EuropeAssistanceCristalliModal';
import EuropeAssistanceCollisioneModal from '../Modal/EuropeAssistanceCollisioneModal';

function RiskCard({
  quote, type, selectQuotation, requestInfo,
}) {
  const [showModalAssistenza, setShowModalAssistenza] = useState(false);
  const [showModalGlobalTutela, setShowModalGlobalTutela] = useState(false);
  const [showModalGlobalInfortuni, setShowModalGlobalInfortuni] = useState(false);
  const [showModalGlobalCristalli, setShowModalGlobalCristalli] = useState(false);
  const [showModalEuropeAssistenza, setShowModalEuropeAssistenza] = useState(false);
  const [showModalEuropeTutela, setShowModalEuropeTutela] = useState(false);
  const [showModalEuropeCristalli, setShowModalEuropeCristalli] = useState(false);
  const [showModalEuropeCollisione, setShowModalEuropeCollisione] = useState(false);

  const openModal = () => {
    if (quote.quote_type === 'assistenza_stradale') {
      setShowModalAssistenza(!showModalAssistenza);
    }
    if (quote.quote_type === 'tutela_legale') {
      setShowModalGlobalTutela(!showModalGlobalTutela);
    }
    if (quote.quote_type === 'infortuni_conducente') {
      setShowModalGlobalInfortuni(!showModalGlobalInfortuni);
    }
    if (quote.quote_type === 'cristalli') {
      setShowModalGlobalCristalli(!showModalGlobalCristalli);
    }
  };

  const openModalEurope = () => {
    if (quote.quote_type === 'assistenza_stradale') {
      setShowModalEuropeAssistenza(!showModalEuropeAssistenza);
    }
    if (quote.quote_type === 'tutela_legale') {
      setShowModalEuropeTutela(!showModalEuropeTutela);
    }
    if (quote.quote_type === 'cristalli') {
      setShowModalEuropeCristalli(!showModalEuropeCristalli);
    }
    if (quote.quote_type === 'collisione') {
      setShowModalEuropeCollisione(!showModalEuropeCollisione);
    }
  };
  return (
    <ProfessionCardStyles className={quote.is_aquista === 1 ? 'selected' : ''}>
      {showModalAssistenza && (
        <GlobalAssistencaStradaleModal
          quotation={quote}
          setShowModal={setShowModalAssistenza}
        />
      )}
      {showModalGlobalTutela && (
        <GlobalAssistanceTutelaModal
          quotation={quote}
          setShowModal={setShowModalGlobalTutela}
        />
      )}
      {showModalGlobalInfortuni && (
        <GlobalAssistanceInfortuniModal
          quotation={quote}
          setShowModal={setShowModalGlobalInfortuni}
        />
      )}
      {showModalGlobalCristalli && (
        <GlobalAssistanceCristalliModal
          quotation={quote}
          setShowModal={setShowModalGlobalCristalli}
        />
      )}
      {showModalEuropeAssistenza && (
        <EuropeAssistencaStradaleModal
          quotation={quote}
          setShowModal={setShowModalEuropeAssistenza}
        />
      )}
      {showModalEuropeTutela && (
        <EuropeAssistanceTutelaModal
          quotation={quote}
          setShowModal={setShowModalEuropeTutela}
        />
      )}
      {showModalEuropeCristalli && (
        <EuropeAssistanceCristalliModal
          quotation={quote}
          setShowModal={setShowModalEuropeCristalli}
        />
      )}
      {showModalEuropeCollisione && (
        <EuropeAssistanceCollisioneModal
          quotation={quote}
          setShowModal={setShowModalEuropeCollisione}
        />
      )}
      <div className="row-container">
        <LeftCol>
          <LogoPriceContainer>
            <LogoContainer>
              <img
                src={BACKEND_BASE_URL + quote.company.logo}
                alt={quote.company.name}
              />
            </LogoContainer>
            <PriceContainer>
              <Price>
                <h5>Prezzo annuo:</h5>
                <h2>
                  &euro;
                  {Number(quote.amount).toLocaleString('it-IT', {
                    minimumFractionDigits: 2,
                  })}
                </h2>
              </Price>
            </PriceContainer>
          </LogoPriceContainer>
          {/* <CompanyInformationContainer>
            {quote.quote_type === 'tutela_legale' && (
              <>
                <CompanyInfoElements
                  onClick={() => downloadResourceDocs(10, 0)}
                  title="questionario_pi_tutela_legale"
                >
                  <img src={preventiveIcon} alt="" />
                  <p>Questionario</p>
                </CompanyInfoElements>
                <CompanyInfoElements
                  onClick={() => downloadResourceDocs(9, 0)}
                  title="set_informativo_pi_Tutela_legale"
                >
                  <img src={preContractIcon} alt="" />
                  <p>Informativa Precontrattuale</p>
                </CompanyInfoElements>
              </>
            )}
          </CompanyInformationContainer> */}
          {quote.quote_type === 'assistenza_stradale'
            && quote.company_id === 16 && (
              <CompanyInformationContainer>
                <CompanyInfoElements onClick={() => openModal()}>
                  <img src={infoIcon} alt="" />
                  <p>Maggiori Informazioni</p>
                </CompanyInfoElements>
              </CompanyInformationContainer>
          )}
          {quote.quote_type === 'assistenza_stradale'
            && quote.company_id === 8 && (
              <CompanyInformationContainer>
                <CompanyInfoElements onClick={() => openModalEurope()}>
                  <img src={infoIcon} alt="" />
                  <p>Maggiori Informazioni</p>
                </CompanyInfoElements>
              </CompanyInformationContainer>
          )}
          {quote.quote_type === 'tutela_legale' && quote.company_id === 16 && (
            <CompanyInformationContainer>
              <CompanyInfoElements onClick={() => openModal()}>
                <img src={infoIcon} alt="" />
                <p>Maggiori Informazioni</p>
              </CompanyInfoElements>
            </CompanyInformationContainer>
          )}
          {quote.quote_type === 'tutela_legale' && quote.company_id === 8 && (
            <CompanyInformationContainer>
              <CompanyInfoElements onClick={() => openModalEurope()}>
                <img src={infoIcon} alt="" />
                <p>Maggiori Informazioni</p>
              </CompanyInfoElements>
            </CompanyInformationContainer>
          )}
          {quote.quote_type === 'infortuni_conducente'
            && quote.company_id === 16 && (
              <CompanyInformationContainer>
                <CompanyInfoElements onClick={() => openModal()}>
                  <img src={infoIcon} alt="" />
                  <p>Maggiori Informazioni</p>
                </CompanyInfoElements>
              </CompanyInformationContainer>
          )}
          {quote.quote_type === 'cristalli' && quote.company_id === 16 && (
            <CompanyInformationContainer>
              <CompanyInfoElements onClick={() => openModal()}>
                <img src={infoIcon} alt="" />
                <p>Maggiori Informazioni</p>
              </CompanyInfoElements>
            </CompanyInformationContainer>
          )}
          {quote.quote_type === 'cristalli' && quote.company_id === 8 && (
            <CompanyInformationContainer>
              <CompanyInfoElements onClick={() => openModalEurope()}>
                <img src={infoIcon} alt="" />
                <p>Maggiori Informazioni</p>
              </CompanyInfoElements>
            </CompanyInformationContainer>
          )}
          {quote.quote_type === 'collisione' && quote.company_id === 8 && (
            <CompanyInformationContainer>
              <CompanyInfoElements onClick={() => openModalEurope()}>
                <img src={infoIcon} alt="" />
                <p>Maggiori Informazioni</p>
              </CompanyInfoElements>
            </CompanyInformationContainer>
          )}
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Compagnia:</h6>
              <p>{quote.company.name}</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Prodotto:</h6>
              <p>{type.name}</p>
            </QuoteInformationItem>
            {((quote.risk_massimale != 0 && quote.risk_massimale !== null)
              || (quote.massimale !== null && quote.massimale != 0)) && (
              <QuoteInformationItem>
                <h6>Massimale:</h6>
                <p>
                  {quote.risk_massimale !== null
                    ? Number(quote.risk_massimale)
                      .toLocaleString()
                      .toLocaleString('it-IT', {
                        minimumFractionDigits: 2,
                      })
                    : Number(quote.massimale)
                      .toLocaleString()
                      .toLocaleString('it-IT', {
                        minimumFractionDigits: 2,
                      })}
                  &euro;
                </p>
              </QuoteInformationItem>
            )}
          </QuoteInformationContainer>
        </LeftCol>
        <RightCol>
          <AquistaButtonsContainer>
            {type.id === 'assistenza_stradale' && (
              <>
                {requestInfo.assistenza_stradale_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => selectQuotation(quote.id)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.assistenza_stradale_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
            {type.id === 'cristalli' && (
              <>
                {requestInfo.cristalli_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => selectQuotation(quote.id)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.cristalli_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
            {type.id === 'infortuni_conducente' && (
              <>
                {requestInfo.infortuni_conducente_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => selectQuotation(quote.id)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.infortuni_conducente_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
            {type.id === 'rivalsa' && (
              <>
                {requestInfo.rivalsa_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => selectQuotation(quote.id)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.rivalsa_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
            {type.id === 'tutela_legale' && (
              <>
                {requestInfo.tutela_legale_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => selectQuotation(quote.id)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.tutela_legale_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
            {type.id === 'furto_incendio' && (
              <>
                {requestInfo.furto_incendio_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => selectQuotation(quote.id)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.furto_incendio_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
            {type.id === 'collisione' && (
              <>
                {requestInfo.collisione_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => selectQuotation(quote.id)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.collisione_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
            {type.id === 'eventi_naturali' && (
              <>
                {requestInfo.collisione_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => selectQuotation(quote.id)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.collisione_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
            {type.id === 'eventi_socio_politici' && (
              <>
                {requestInfo.collisione_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => selectQuotation(quote.id)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.collisione_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
          </AquistaButtonsContainer>
        </RightCol>
      </div>
    </ProfessionCardStyles>
  );
}

export default RiskCard;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';
import Car from '../assets/images/car.png';
import CarWhite from '../assets/images/car-white.png';
import Moto from '../assets/images/moto.png';
import MotoWhite from '../assets/images/moto-white.png';
import Truck from '../assets/images/truck.png';
import TruckWhite from '../assets/images/truck-white.png';
import Professional from '../assets/images/professional.png';
import ProfessionalWhite from '../assets/images/professional-white.png';
import TripsWhite from '../assets/images/trips-white.png';
import Trips from '../assets/images/trips.png';
import ProductCard from '../common/components/ProductCard';
import StorageService from '../services/StorageService';
import JobImage from '../assets/images/job.png';
// import { useTourContext } from '../context/useTourContext';
import HomeWhite from '../assets/images/home-white.png';
import Home from '../assets/images/home.png';

// import commercialImage from '../assets/images/commercial.png';
// import commercialImageWhite from '../assets/images/commercial_white.png';
import PageLoading from '../common/components/elements/PageLoading';
import { getAvailableProducts } from '../features/auth/authActions';
// import { joyrideOptions } from '../common/utils/joyrideUtils';
// import { handleCallbackNewPreventivo } from '../common/utils/joyrideCallbacks';
// import { quoteSelectProductSteps } from '../common/utils/joyrideSteps';

const storage = new StorageService();

export default function Quote() {
  const [product, setProduct] = useState('');
  const { loading, availableProducts } = useSelector((store) => store.auth.user_products);
  const navigate = useNavigate();
  const navigateToProductForm = (product) => {
    setProduct(product);
    if (product === 'auto' || product === 'autocarro') {
      storage.removeVehicleDriverData();
      storage.removeVehicleOwnerData();
      storage.removeVehicleQuoteAnswers();
      storage.removeBersani();
    } else if (product === 'profession') {
      storage.removeSelectedProfession();
    }

    if (product === 'motorcycle') {
      storage.removeMotoQuoteAnswers();
    }

    if (product === 'metlife') {
      navigate('/quote/metlife');
    } else if (product === 'infodrive') {
      navigate('/quote/infodrive');
    } else {
      navigate(`/quote/${product}`);
    }
    if (product === 'travel') {
      storage.removeTravelData();
      navigate('/quote/travel');
    }

    // if (product === 'casa') {
    //   // storage.removeTravelData();
    //   navigate('/quote/casa');
    // }
  };

  useEffect(() => {
    getAvailableProducts();
  }, []);

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('selectProduct') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('selectProduct')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('selectProduct');
  //   setState({ run: true, tourActive: true });
  // };

  // let availableProducts = [1, 2, 3, 4, 5, 6, 8, 9];

  // const user = storage.getUser();
  // if (user.available_products !== undefined) {
  //   availableProducts = user.available_products;
  // }

  const isAvailable = (productId) => availableProducts.includes(productId);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div className="quote">
      {/* <Joyride
        steps={quoteSelectProductSteps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,
          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackNewPreventivo(data, quoteSelectProductSteps, setState)}
        showSkipButton
        showProgress
      />

      <div className="d-flex justify-content-end align-items-end">
        {localStorage.getItem('selectProduct') === 'done' && (
          <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()}>
            <button className="btn btn-success btn-sm text-white px-3">Tutorial</button>
          </div>
        )}
      </div> */}

      <div className="quote-group">
        <div className="quote-h1 text-center">
          <h1>NUOVO PREVENTIVO</h1>
        </div>
        <div className="quote-h6">
          <h6>Cosa vuoi proteggere?</h6>
        </div>
      </div>
      <div className="container text-center select-product-one">
        <div className="row justify-content-center g-2 g-md-3 ">
          {isAvailable(1) === true && (
            <ProductCard
              title="Auto"
              isActive={product === 'auto'}
              onSelect={() => navigateToProductForm('auto')}
              imageWhite={CarWhite}
              iamgeDark={Car}
            />
          )}
          {isAvailable(2) === true && (
            <ProductCard
              title="Moto"
              isActive={product === 'motorcycle'}
              onSelect={() => navigateToProductForm('motorcycle')}
              imageWhite={MotoWhite}
              iamgeDark={Moto}
            />
          )}
          {isAvailable(3) === true && (
            <ProductCard
              title="Autocarro"
              isActive={product === 'autocarro'}
              onSelect={() => navigateToProductForm('autocarro')}
              imageWhite={TruckWhite}
              iamgeDark={Truck}
            />
          )}
          {isAvailable(4) === true && (
            <ProductCard
              title="Professionale"
              isActive={product === 'profession'}
              onSelect={() => navigateToProductForm('profession')}
              imageWhite={JobImage}
              iamgeDark={JobImage}
            />
          )}
          {/* {isAvailable(5) === true &&
                        <ProductCard title={"MetLife"} isActive={product === 'metlife'} onSelect={() => setProduct('metlife')} imageWhite={InjuriesWhite} iamgeDark={Injuries} />
                    } */}
          {isAvailable(8) === true
            && <ProductCard title="Viaggi" isActive={product === 'travel'} onSelect={() => navigateToProductForm('travel')} imageWhite={TripsWhite} iamgeDark={Trips} />}
          {isAvailable(9) === true
            && <ProductCard title="Tutela Legale" isActive={product === 'tutela_legale'} onSelect={() => navigateToProductForm('tutela-legale')} imageWhite={ProfessionalWhite} iamgeDark={Professional} />}

          {isAvailable(11) === true && <ProductCard title="Casa" isActive={product === 'casa'} onSelect={() => navigateToProductForm('casa')} imageWhite={HomeWhite} iamgeDark={Home} />}

          {/* {isAvailable(6) === true &&
              <ProductCard title={"InfoDrive"} isActive={product === 'infodrive'} onSelect={() => setProduct('infodrive')} imageWhite={InfoDrive} iamgeDark={InfoDrive} />
          } */}

          {/* {isAvailable(4) === true && */}
          {' '}
          {/* //todo remove this comment and check for broker if is able to quote for this product  */}
          {/* {isAvailable(10) === true && <ProductCard title="Commercial" isActive={product === 'commercial'} onSelect={() => navigateToProductForm('commercial')} imageWhite={commercialImageWhite} iamgeDark={commercialImage} />} */}

        </div>
      </div>
    </div>
  );
}

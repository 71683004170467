import styled from 'styled-components';

export const ClientBox = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  /* flex-basis: 300px; */

  table {
    thead {
      background-color: rgb(153, 219, 185);
    }
    tfoot{
      background-color: rgb(153, 219, 185);
    }

  }

  h4 {
    color: rgb(153, 219, 185);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    margin-bottom: 0;
  }

  hr {
    color: #00a651;
    width: 100%;
  }

  h6 {
    color: #00a651;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    margin-bottom: 0;
  }

  h5 {
    color: var(--common-black, #1a1921);
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 0;
  }
`;

export const VehicleBox = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  flex-basis: 300px;

  h6 {
    color: #00a651;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
    margin-bottom: 0;
  }
`;

export const InfortuniPackBox = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  flex-basis: 300px;

  h6 {
    color: #00a651;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
    margin-bottom: 0;
  }
`;

export const ProfessioneProductBox = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  flex-basis: 300px;

  h6 {
    color: #00a651;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
    margin-bottom: 0;
  }
`;

export const TravelProductBox = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  flex-basis: 300px;

  h6 {
    color: #00a651;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
    margin-bottom: 0;
  }
`;

export const QuoteInfoBox = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  flex-basis: 300px;

  h6 {
    color: #00a651;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
    margin-bottom: 0;
  }

  h4 {
    color: var(--common-black, #1a1921);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }
`;

export const ContactBox = styled.div`
  width: 100%;
  background: transparent;
  border: none;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;

  img {
    width: 50px;
  }

  h5 {
    color: #00a651;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 26px; /* 107.692% */
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const MessageNumber = styled.div`
  width: 30px;
  height: 30px;
  background-color: red;
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`;

export const BoxWrapper = styled.div`
  /* display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
  flex-wrap: wrap; */
  margin-top: 15px;
`;

export const BoxMeta = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
`;

export const MetaBoxCol = styled.div`
  margin-top: 30px;
  h6 {
    color: #1a1921;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }

  p {
    color: var(--common-black, #1a1921);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }
`;

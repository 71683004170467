import caller from './purchasesSlice';
import axe from '../../services/axios-client/axiosClient';
import { generalAlertError, generalAlertSuccess } from '../../common/utils/alerts';

/**
 * Auto
 */

export const autoUpdateState = (data) => {
  caller.autoUpdateState(data);
};

export const autoCollectData = async (quotationId, setSupportMessage) => {
  const res = await axe.get(`/quote/${quotationId}`);
  const { data: resBody } = res;
  caller.autoUpdateState({
    loading: false,
    insuranceRequest: resBody.data.insurance_request,
    uploadedFirstDocs: {
      id_card: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 1,
      )
        ? 1
        : 0,
      vehicle_card: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 2,
      )
        ? 1
        : 0,
      libretto_veicolo_bersani: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 20,
      )
        ? 1
        : 0,
      stato_di_famiglia: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 21,
      )
        ? 1
        : 0,
      id_card_famigliare: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 22,
      )
        ? 1
        : 0,
      visura_camerale: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 24,
      )
        ? 1
        : 0,
    },
    quoteStatus: resBody.data.status,
    quote: resBody.data,
    nonSignableDocs: resBody.data.nonSignableDocs,
    signableDocs: resBody.data.signableDocs,
  });
  setSupportMessage((supportMessage) => ({
    ...supportMessage,
    request_id: resBody.data.insurance_request.id,
  }));
};

export const autoSelectSignMethod = async (
  signMethod,
  quotationId,
  collectQuoteData,
  signature_mail = null,
) => {
  caller.autoUpdateState({
    loading: true,
  });

  if (signMethod === 0) {
    await axe.post(`/quotations/${quotationId}/manually/sign/documents`);
  } else {
    await axe.post(`/quotations/${quotationId}/digitally/sign/documents`, {
      signature_mail,
    });
  }

  collectQuoteData();
};

export const autoGetBackofficeUsers = async () => {
  const res = await axe.get('/get-users');
  caller.autoUpdateState({
    backofficeUsers: res.data.data,
  });
};

export const updateBrokerage = async (quoteId, brokerage_amount, collectData, skipCollect = false) => {
  if (!skipCollect) {
    caller.autoUpdateState({
      loading: true,
    });
  }
  const res = await axe.post(
    `/quotations/brokerage/${quoteId}/update`,
    {
      brokerage_amount,
    },
  );
  if (res.status !== 200) {
    caller.autoUpdateState({
      loading: false,
    });
    generalAlertError('Errore Interno!');
  }

  if (res.status === 200) {
    if (!skipCollect) {
      collectData();
    }
  }
};

export const updateService = async (quoteId, service_gross_amount, collectData, skipCollect = false) => {
  if (!skipCollect) {
    caller.autoUpdateState({
      loading: true,
    });
  }
  const res = await axe.post(
    `/quotations/services/${quoteId}/update`,
    {
      service_gross_amount,
    },
  );
  if (res.status !== 200) {
    caller.autoUpdateState({
      loading: false,
    });
    generalAlertError('Errore Interno!');
  }

  if (res.status === 200) {
    if (!skipCollect) {
      collectData();
    }
  }
};

export const updateBrokerageProfessionale = async (quoteId, brokerage_amount, collectData, skipCollect = false) => {
  if (!skipCollect) {
    caller.professionUpdateState({
      loading: true,
    });
  }
  const res = await axe.post(
    `/quotations/brokerage/${quoteId}/update`,
    {
      brokerage_amount,
    },
  );
  if (res.status !== 200) {
    caller.professionUpdateState({
      loading: false,
    });
    generalAlertError('Errore Interno!');
  }

  if (res.status === 200) {
    if (!skipCollect) {
      collectData();
    }
  }
};

export const confirmSelectedForBackofficeQuote = async (
  quoteId,
  collectQuoteData,
) => {
  caller.autoUpdateState({
    loading: true,
  });
  const res = await axe.post(`/quotations/backoffice/selected/${quoteId}`);

  if (res.status === 200) {
    collectQuoteData();
  }
};

export const autoResendInvalidSignedDocs = async (
  quotationId,
  collectQuoteData,
) => {
  caller.autoUpdateState({
    loading: true,
  });
  await axe.post(`/quotations/${quotationId}/digitally/sign/documents/resend`);
  collectQuoteData();
};

export const resetAutoPurchase = () => {
  caller.autoResetState();
};

export const autoReminderToSignDocs = async (quotationId, collectQuoteData) => {
  caller.autoUpdateState({
    loading: true,
  });
  const res = await axe.post(
    `/quotations/${quotationId}/digitally/sign/documents/reminder`,
  );

  if (res.status === 200) {
    generalAlertSuccess('Un email e stato mando al cliente!');
  }
  collectQuoteData();
};

export const skipSignature = async (
  quoteId,
  navigate,
) => {
  caller.autoUpdateState({
    loading: true,
  });
  const res = await axe.post(`/quotations/skip-signature/${quoteId}`);

  if (res.status !== 200 && res.status !== 404) {
    generalAlertError('Per favore carica i documenti del cliente.');
    caller.autoUpdateState({
      loading: false,
    });
  }

  if (res.status === 200) {
    generalAlertSuccess('La pratica è completa, attendi nostro feedback!');
    navigate('/');
  }
};

export const continueWithSignature = async (
  quoteId,
  collectData,
) => {
  caller.autoUpdateState({
    loading: true,
  });
  const res = await axe.post(`/quotations/continue-with-signature/${quoteId}`);

  if (res.status === 200) {
    collectData();
  }
};

export const skipSignatureMoto = async (
  quoteId,
  navigate,
) => {
  caller.motoUpdateState({
    loading: true,
  });
  const res = await axe.post(`/quotations/skip-signature/${quoteId}`);

  if (res.status !== 200) {
    generalAlertError('Per favore carica i documenti del cliente.');
    caller.motoUpdateState({
      loading: false,
    });
  }

  if (res.status === 200) {
    generalAlertSuccess('La pratica è completa, attendi nostro feedback!');
    navigate('/');
  }
};

export const continueWithSignatureMoto = async (
  quoteId,
  collectData,
) => {
  caller.motoUpdateState({
    loading: true,
  });
  const res = await axe.post(`/quotations/continue-with-signature/${quoteId}`);

  if (res.status === 200) {
    collectData();
  }
};

/**
 * Autocarro
 */

export const autocarroUpdateState = (data) => {
  caller.autocarroUpdateState(data);
};

export const autocarroCollectData = async (quotationId, setSupportMessage) => {
  const res = await axe.get(`/quote/${quotationId}`);
  const { data: resBody } = res;
  caller.autocarroUpdateState({
    loading: false,
    insuranceRequest: resBody.data.insurance_request,
    uploadedFirstDocs: {
      id_card: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 1,
      )
        ? 1
        : 0,
      vehicle_card: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 2,
      )
        ? 1
        : 0,
      libretto_veicolo_bersani: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 20,
      )
        ? 1
        : 0,
      stato_di_famiglia: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 21,
      )
        ? 1
        : 0,
      id_card_famigliare: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 22,
      )
        ? 1
        : 0,
      visura_camerale: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 24,
      )
        ? 1
        : 0,
    },
    quoteStatus: resBody.data.status,
    quotationId: resBody.data.id,
    quote: resBody.data,
  });
  setSupportMessage((supportMessage) => ({
    ...supportMessage,
    request_id: resBody.data.insurance_request.id,
  }));
};

export const autocarroSelectSignMethod = async (
  signMethod,
  quotationId,
  collectQuoteData,
  signature_mail = null,
) => {
  caller.autocarroUpdateState({
    loading: true,
  });

  if (signMethod === 0) {
    await axe.post(`/quotations/${quotationId}/manually/sign/documents`);
  } else {
    await axe.post(`/quotations/${quotationId}/digitally/sign/documents`, {
      signature_mail,
    });
  }

  collectQuoteData();
};

export const autocarroGetBackofficeUsers = async () => {
  const res = await axe.get('/get-users');
  caller.autocarroUpdateState({
    backofficeUsers: res.data.data,
  });
};

export const autocarroResendInvalidSignedDocs = async (
  quotationId,
  collectQuoteData,
) => {
  caller.autocarroUpdateState({
    loading: true,
  });
  await axe.post(`/quotations/${quotationId}/digitally/sign/documents/resend`);
  collectQuoteData();
};

export const autocarroReminderToSignDocs = async (
  quotationId,
  collectQuoteData,
) => {
  caller.autoUpdateState({
    loading: true,
  });
  const res = await axe.post(
    `/quotations/${quotationId}/digitally/sign/documents/reminder`,
  );

  if (res.status === 200) {
    generalAlertSuccess('Un email e stato mando al cliente!');
  }
  collectQuoteData();
};

export const resetAutocarroPurchase = () => {
  caller.autocarroResetState();
};

export const skipSignatureAutocarro = async (
  quoteId,
  navigate,
) => {
  caller.autocarroUpdateState({
    loading: true,
  });
  const res = await axe.post(`/quotations/skip-signature/${quoteId}`);

  if (res.status !== 200) {
    generalAlertError('Per favore carica i documenti del cliente.');
    caller.autocarroUpdateState({
      loading: false,
    });
  }

  if (res.status === 200) {
    generalAlertSuccess('La pratica è completa, attendi nostro feedback!');
    navigate('/');
  }
};

export const continueWithSignatureAutocarro = async (
  quoteId,
  collectData,
) => {
  caller.autoUpdateState({
    loading: true,
  });
  const res = await axe.post(`/quotations/continue-with-signature/${quoteId}`);

  if (res.status === 200) {
    collectData();
  }
};

/**
 * Moto
 */

export const motoUpdateState = (data) => {
  caller.motoUpdateState(data);
};

export const motoCollectData = async (quotationId, setSupportMessage) => {
  const res = await axe.get(`/quote/moto/${quotationId}`);
  const { data: resBody } = res;
  caller.motoUpdateState({
    insuranceRequest: resBody.data.insurance_request,
    uploadedFirstDocs: {
      id_card: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 1,
      )
        ? 1
        : 0,
      vehicle_card: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 2,
      )
        ? 1
        : 0,
      libretto_veicolo_bersani: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 20,
      )
        ? 1
        : 0,
      stato_di_famiglia: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 21,
      )
        ? 1
        : 0,
      id_card_famigliare: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 22,
      )
        ? 1
        : 0,
      visura_camerale: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 24,
      )
        ? 1
        : 0,
    },
    quoteStatus: resBody.data.status,
    quote: resBody.data,
    loading: false,
  });
  setSupportMessage((supportMessage) => ({
    ...supportMessage,
    request_id: resBody.data.insurance_request.id,
  }));
};

export const motoSelectSignMethod = async (
  signMethod,
  quotationId,
  collectQuoteData,
  signature_mail = null,
) => {
  caller.motoUpdateState({
    loading: true,
  });

  if (signMethod === 0) {
    await axe.post(`/quotations/${quotationId}/manually/sign/documents`);
  } else {
    await axe.post(`/quotations/${quotationId}/digitally/sign/documents`, {
      signature_mail,
    });
  }

  collectQuoteData();
};

export const motoGetBackofficeUsers = async () => {
  const res = await axe.get('/get-users');
  caller.motoUpdateState({
    backofficeUsers: res.data.data,
  });
};

export const motoResendInvalidSignedDocs = async (
  quotationId,
  collectQuoteData,
) => {
  caller.motoUpdateState({
    loading: true,
  });
  await axe.post(`/quotations/${quotationId}/digitally/sign/documents/resend`);
  collectQuoteData();
};

export const resetMotoPurchase = () => {
  caller.motoResetState();
};

export const motoReminderToSignDocs = async (quotationId, collectQuoteData) => {
  caller.motoUpdateState({
    loading: true,
  });
  const res = await axe.post(
    `/quotations/${quotationId}/digitally/sign/documents/reminder`,
  );

  if (res.status === 200) {
    generalAlertSuccess('Un email e stato mando al cliente!');
  }
  collectQuoteData();
};

/**
 * Profession
 */

export const professionUpdateState = (data) => {
  caller.professionUpdateState(data);
};

export const professionCollectData = async (quotationId, setSupportMessage) => {
  const res = await axe.get(`/quotations/profession/${quotationId}`);

  const { data: resBody } = res;

  caller.professionUpdateState({
    loading: false,
    insuranceRequest: resBody.data.insurance_request,
    uploadedFirstDocs: {
      id_card: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 1,
      )
        ? 1
        : 0,
      modulo_di_proposta: resBody.data.quotation.documents.find(
        (d) => d.document_type_id === 5,
      )
        ? 1
        : 0,
      visura_camerale: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 24,
      )
        ? 1
        : 0,
    },
    quoteStatus: resBody.data.quotation.status,
    quote: resBody.data.quotation,
  });
  setSupportMessage((supportMessage) => ({
    ...supportMessage,
    request_id: resBody.data.insurance_request.id,
  }));
};

export const professionSelectSignMethod = async (
  signMethod,
  quotationId,
  collectQuoteData,
) => {
  caller.professionUpdateState({
    loading: true,
  });

  if (signMethod === 0) {
    await axe.post(`/quotations/${quotationId}/manually/sign/documents`);
  } else {
    await axe.post(`/quotations/${quotationId}/digitally/sign/documents`);
  }

  collectQuoteData();
};

export const professionGetBackofficeUsers = async () => {
  const res = await axe.get('/get-users');
  caller.professionUpdateState({
    backofficeUsers: res.data.data,
  });
};

export const professionResendInvalidSignedDocs = async (
  quotationId,
  collectQuoteData,
) => {
  caller.professionUpdateState({
    loading: true,
  });
  await axe.post(`/quotations/${quotationId}/digitally/sign/documents/resend`);
  collectQuoteData();
};

export const resetProfessionPurchase = () => {
  caller.professionResetState();
};

export const skipSignatureProfession = async (
  quoteId,
  navigate,
) => {
  caller.professionUpdateState({
    loading: true,
  });
  const res = await axe.post(`/quotations/skip-signature/${quoteId}`);

  if (res.status !== 200) {
    generalAlertError('Per favore carica i documenti del cliente.');
    caller.professionUpdateState({
      loading: false,
    });
  }

  if (res.status === 200) {
    generalAlertSuccess('La pratica è completa, attendi nostro feedback!');
    navigate('/');
  }
};

export const resetHousePurchase = () => {
  caller.houseResetState();
};

export const skipSignatureHouse = async (
  quoteId,
  navigate,
) => {
  caller.houseUpdateState({
    loading: true,
  });
  const res = await axe.post(`/quotations/skip-signature/${quoteId}`);

  if (res.status !== 200) {
    generalAlertError('Per favore carica i documenti del cliente.');
    caller.houseUpdateState({
      loading: false,
    });
  }

  if (res.status === 200) {
    generalAlertSuccess('La pratica è completa, attendi nostro feedback!');
    navigate('/');
  }
};

export const continueWithSignatureProfession = async (
  quoteId,
  collectData,
) => {
  caller.professionUpdateState({
    loading: true,
  });
  const res = await axe.post(`/quotations/continue-with-signature/${quoteId}`);

  if (res.status === 200) {
    collectData();
  }
};

export const continueWithSignatureHouse = async (
  quoteId,
  collectData,
) => {
  caller.houseUpdateState({
    loading: true,
  });
  const res = await axe.post(`/quotations/continue-with-signature/${quoteId}`);

  if (res.status === 200) {
    collectData();
  }
};

/**
 * Infortuni
 */

export const infortuniUpdateState = (data) => {
  caller.infortuniUpdateState(data);
};

export const infortuniCollectData = async (quotationId, setSupportMessage) => {
  const res = await axe.get(`/infortuni/metlife/get-quote/${quotationId}`);
  const { data: resBody } = res;
  caller.infortuniUpdateState({
    loading: false,
    insuranceRequest: resBody.data.insurance_request,
    uploadedFirstDocs: {
      id_card: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 1,
      )
        ? 1
        : 0,
      visura_camerale: resBody.data.insurance_request.documents.find(
        (d) => d.document_type_id === 24,
      )
        ? 1
        : 0,
    },
    quoteStatus: resBody.data.quotation.status,
    quote: resBody.data.quotation,
  });
  setSupportMessage((supportMessage) => ({
    ...supportMessage,
    request_id: resBody.data.insurance_request.id,
  }));
};

export const infortuniSelectSignMethod = async (
  signMethod,
  quotationId,
  collectQuoteData,
  signature_mail = null,
) => {
  caller.infortuniUpdateState({
    loading: true,
  });

  if (signMethod === 0) {
    await axe.post(`/quotations/${quotationId}/manually/sign/documents`);
  } else {
    await axe.post(`/quotations/${quotationId}/digitally/sign/documents`, {
      signature_mail,
    });
  }

  collectQuoteData();
};

export const infortuniGetBackofficeUsers = async () => {
  const res = await axe.get('/get-users');
  caller.infortuniUpdateState({
    backofficeUsers: res.data.data,
  });
};

export const infortuniResendInvalidSignedDocs = async (
  quotationId,
  collectQuoteData,
) => {
  caller.infortuniUpdateState({
    loading: true,
  });
  await axe.post(`/quotations/${quotationId}/digitally/sign/documents/resend`);
  collectQuoteData();
};

export const infortuniReminderToSignDocs = async (
  quotationId,
  collectQuoteData,
) => {
  caller.infortuniUpdateState({
    loading: true,
  });
  const res = await axe.post(
    `/quotations/${quotationId}/digitally/sign/documents/reminder`,
  );

  if (res.status === 200) {
    generalAlertSuccess('Un email e stato mando al cliente!');
  }

  collectQuoteData();
};

export const resetInfortuniPurchase = () => {
  caller.infortuniResetState();
};

export const infodriveCollectData = async (quotationId, setSupportMessage) => {
  const res = await axe.get(`/infodrive/get-quote/${quotationId}`);
  const { data: resBody } = res;

  caller.infodriveUpdateState({
    loading: false,
    insuranceRequest: resBody.data.insurance_request,
    quoteStatus: resBody.data.quotation.status,
    quote: resBody.data.quotation,
    infodriveQuotationData: resBody.data.infodrive_quotation_data,
  });
  setSupportMessage((supportMessage) => ({
    ...supportMessage,
    request_id: resBody.data.insurance_request.id,
  }));
};

export const infodriveGetBackofficeUsers = async () => {
  const res = await axe.get('/get-users');
  caller.infodriveUpdateState({
    backofficeUsers: res.data.data,
  });
};

export const resetInfodrivePurchase = () => {
  caller.infodriveResetState();
};

/**
 * Travel
 */

export const travelCollectData = async (quotationId, setSupportMessage) => {
  const res = await axe.get(`/quote/travel/${quotationId}`);

  const { data } = res;

  caller.travelUpdateState({
    loading: false,
    quotation: data.data.quotation,
    insuranceRequest: data.data.insurance_request,
    quoteStatus: data.data.quotation.status,
  });
  setSupportMessage((supportMessage) => ({
    ...supportMessage,
    request_id: data.data.insurance_request.id,
  }));
};

export const resetTravelPurchase = () => {
  caller.travelResetState();
};

export const travelGetBackofficeUsers = async () => {
  const res = await axe.get('/get-users');
  caller.travelUpdateState({
    backofficeUsers: res.data.data,
  });
};

export const updateBrokerageTravel = async (quoteId, brokerage_amount, collectData, skipCollect = false) => {
  if (!skipCollect) {
    caller.travelUpdateState({
      loading: true,
    });
  }
  const res = await axe.post(
    `/quotations/brokerage/${quoteId}/update`,
    {
      brokerage_amount,
    },
  );
  if (res.status !== 200) {
    caller.travelUpdateState({
      loading: false,
    });
    generalAlertError('Errore Interno!');
  }

  if (res.status === 200) {
    if (!skipCollect) {
      collectData();
    }
  }
};

/**
 *
 * Commercial
 *
 * @param {*} quotationId
 * @param {*} setSupportMessage
 */

export const resetCommercialPurchase = () => {
  caller.commercialResetState();
};

export const commercialGetBackofficeUsers = async () => {
  const res = await axe.get('/get-users');
  caller.commercialUpdateState({
    backofficeUsers: res.data.data,
  });
};

const handleInsuranceRequestsUpdateByProduct = (store_key, payload) => {
  switch (store_key) {
    case 'auto':
      caller.autoUpdateState({
        ...payload,
      });
      break;
    case 'autocarro':
      caller.autocarroUpdateState({
        ...payload,
      });
      break;
    case 'moto':
      caller.motoUpdateState({
        ...payload,
      });
      break;
    case 'profession':
      caller.professionUpdateState({
        ...payload,
      });
      break;
    case 'infortuni':
      caller.infodriveUpdateState({
        ...payload,
      });
      break;
    case 'infodrive':
      caller.infodriveUpdateState({
        ...payload,
      });
      break;
    case 'travel':
      caller.travelUpdateState({
        ...payload,
      });
      break;
    case 'commercial':
      caller.commercialUpdateState({
        ...payload,
      });
      break;
    case 'tutela-legale':
      caller.tutelaLegaleUpdateState({
        ...payload,
      });
      break;
    case 'casa':
      caller.houseUpdateState({
        ...payload,
      });
      break;
    default:
      break;
  }
};

// ? TODO
const checkIfDocumentsAreUploaded = (productType, response) => {
  let uploadedDocs = {};
  if (productType === 'profession') {
    uploadedDocs = {
      id_card: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 1,
      )
        ? 1
        : 0,
      modulo_di_proposta: response.data.data.rc_quotation.documents.find(
        (d) => d.document_type_id === 5,
      )
        ? 1
        : 0,
      visura_camerale: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 24,
      )
        ? 1
        : 0,
    };
  } else if (productType === 'auto') {
    uploadedDocs = {
      id_card: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 1,
      )
        ? 1
        : 0,
      vehicle_card: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 2,
      )
        ? 1
        : 0,
      libretto_veicolo_bersani: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 20,
      )
        ? 1
        : 0,
      stato_di_famiglia: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 21,
      )
        ? 1
        : 0,
      id_card_famigliare: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 22,
      )
        ? 1
        : 0,
      visura_camerale: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 24,
      )
        ? 1
        : 0,
    };
  } else if (productType === 'autocarro') {
    uploadedDocs = {
      id_card: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 1,
      )
        ? 1
        : 0,
      vehicle_card: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 2,
      )
        ? 1
        : 0,
      libretto_veicolo_bersani: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 20,
      )
        ? 1
        : 0,
      stato_di_famiglia: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 21,
      )
        ? 1
        : 0,
      id_card_famigliare: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 22,
      )
        ? 1
        : 0,
      visura_camerale: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 24,
      )
        ? 1
        : 0,
    };
  } else if (productType === 'moto') {
    uploadedDocs = {
      id_card: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 1,
      )
        ? 1
        : 0,
      vehicle_card: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 2,
      )
        ? 1
        : 0,
      libretto_veicolo_bersani: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 20,
      )
        ? 1
        : 0,
      stato_di_famiglia: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 21,
      )
        ? 1
        : 0,
      id_card_famigliare: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 22,
      )
        ? 1
        : 0,
      visura_camerale: response.data.data.insurance_request.documents.find(
        (d) => d.document_type_id === 24,
      )
        ? 1
        : 0,
    };
  }

  return uploadedDocs;
};

export const purchaseInsuranceRequest = async (request_token, store_key, setSupportMessage) => {
  handleInsuranceRequestsUpdateByProduct(store_key, {
    loading: true,
  });

  try {
    const response = await axe.get(`/insurance-request/save/${request_token}`);
    handleInsuranceRequestsUpdateByProduct(store_key, {
      loading: false,
      rc_quotation: response.data.data.rc_quotation,
      tutela_quotation: response.data.data.tutela_quotation,
      quote: response.data.data.rc_quotation,
      insuranceRequest: response.data.data.insurance_request,
      uploadedFirstDocs: checkIfDocumentsAreUploaded(store_key, response),
      quoteStatus: response.data.data.rc_quotation.status,
      assistenza_quotation: response.data.data.assistenza_quotation,
      cristalli_quotation: response.data.data.cristalli_quotation,
      infortuni_conducente_quotation: response.data.data.infortuni_conducente_quotation,
      rivalsa_quotation: response.data.data.rivalsa_quotation,
      nonSignableDocs: response.data.data.nonSignableDocs,
      requestedOptionalDocumentsNonSignable: response.data.data.requestedOptionalDocumentsNonSignable,
      signableDocs: response.data.data.signableDocs,
      requestedOptionalDocumentsSignable: response.data.data.requestedOptionalDocumentsSignable,
      rct_cose_quote: response.data.data.rct_cose_quote,
      rct_veicoli_quote: response.data.data.rct_veicoli_quote,
      rct_postuma_autofficine_quote: response.data.data.rct_postuma_autofficine_quote,
      rct_postuma_12_quote: response.data.data.rct_postuma_12_quote,
      rct_postuma_dm_quote: response.data.data.rct_postuma_dm_quote,
      rct_furto_quote: response.data.data.rct_furto_quote,
      rco_quote: response.data.data.rco_quote,
      rc_prodotto_quote: response.data.data.rc_prodotto_quote,
      do_quote: response.data.data.do_quote,
      collisione_quotation: response.data.data.collisione_quotation,
      furto_incendio_quotation: response.data.data.furto_incendio_quotation,
      incendioFabbricatoQuote: response.data.data.incendioFabbricatoQuote,
      incendioContenutoQuote: response.data.data.incendioContenutoQuote,
      danniFamigliaConduzioneQuote: response.data.data.danniFamigliaConduzioneQuote,
      danniPropietarioQuote: response.data.data.danniPropietarioQuote,
      speseLegaliFamigliaQuote: response.data.data.speseLegaliFamigliaQuote,
      risk_quotations: response.data.data.risk_quotations,

    });

    setSupportMessage((supportMessage) => ({
      ...supportMessage,
      request_id: response.data.data.insurance_request.id,
    }));
  } catch (error) {
    generalAlertError('Errore Interno.');

    handleInsuranceRequestsUpdateByProduct(store_key, {
      loading: false,
    });
  }
};

export const tutelaLegaleGetBackofficeUsers = async () => {
  const res = await axe.get('/get-users');
  caller.tutelaLegaleUpdateState({
    backofficeUsers: res.data.data,
  });
};
export const tutelaLegaleProfessionPurchase = () => {
  caller.tutelaLegaleResetState();
};

export const getAutoCompletedData = async (quoteId) => {
  caller.autoCompletedUpdateState({
    loading: true,
  });

  const response = await axe.get(`/quote/${quoteId}`);

  if (response.status !== 200) {
    generalAlertError('Preventivo Non Trovato!');
    caller.autoCompletedUpdateState({
      loading: false,
      error: 'Preventivo Non Trovato!',
    });
    return;
  }

  caller.autoCompletedUpdateState({
    loading: false,
    quote: response.data.data,
  });
};

export const autoCompletedDataReset = () => {
  caller.autoCompletedResetState();
};

export const getProfessionCompletedData = async (quoteId) => {
  caller.professionCompletedUpdateState({
    loading: true,
  });

  const response = await axe.get(`/quotations/profession/${quoteId}`);

  if (response.status !== 200) {
    generalAlertError('Preventivo Non Trovato!');
    caller.professionCompletedUpdateState({
      loading: false,
      error: 'Preventivo Non Trovato!',
    });
    return;
  }

  caller.professionCompletedUpdateState({
    loading: false,
    quote: response.data.data.quotation,
    insurance_request: response.data.data.insurance_request,
  });
};

export const professionCompletedDataReset = () => {
  caller.professionCompletedResetState();
};

export const getTravelCompletedData = async (quoteId) => {
  caller.travelCompletedUpdateState({
    loading: true,
  });

  const response = await axe.get(`/quote/travel/${quoteId}`);

  if (response.status !== 200) {
    generalAlertError('Preventivo Non Trovato!');
    caller.travelCompletedUpdateState({
      loading: false,
      error: 'Preventivo Non Trovato!',
    });
    return;
  }

  caller.travelCompletedUpdateState({
    loading: false,
    quote: response.data.data.quotation,
    insurance_request: response.data.data.insurance_request,
  });
};

export const travelCompletedDataReset = () => {
  caller.travelCompletedResetState();
};

export const getTutelaLegaleCompletedData = async (quoteId) => {
  caller.tutelaLegaleCompletedUpdateState({
    loading: true,
  });

  const response = await axe.get(`/quote/tutela-legale/${quoteId}`);

  if (response.status !== 200) {
    generalAlertError('Preventivo Non Trovato!');
    caller.tutelaLegaleCompletedUpdateState({
      loading: false,
      error: 'Preventivo Non Trovato!',
    });
    return;
  }

  caller.tutelaLegaleCompletedUpdateState({
    loading: false,
    quote: response.data.data,
    insurance_request: response.data.data.insurance_request,
  });
};

export const tutelaLegaleCompletedDataReset = () => {
  caller.tutelaLegaleCompletedResetState();
};

export const getAssistenzaStradaleCompletedData = async (quoteId) => {
  caller.tutelaLegaleCompletedUpdateState({
    loading: true,
  });

  const response = await axe.get(`/quote/assistenza-stradale/${quoteId}`);

  if (response.status !== 200) {
    generalAlertError('Preventivo Non Trovato!');
    caller.tutelaLegaleCompletedUpdateState({
      loading: false,
      error: 'Preventivo Non Trovato!',
    });
    return;
  }

  caller.tutelaLegaleCompletedUpdateState({
    loading: false,
    quote: response.data.data,
    insurance_request: response.data.data.insurance_request,
  });
};

export const assistenzaStradaleCompletedDataReset = () => {
  caller.tutelaLegaleCompletedResetState();
};

export const getCollisioneCompletedData = async (quoteId) => {
  caller.tutelaLegaleCompletedUpdateState({
    loading: true,
  });

  const response = await axe.get(`/quote/collisione/${quoteId}`);

  if (response.status !== 200) {
    generalAlertError('Preventivo Non Trovato!');
    caller.tutelaLegaleCompletedUpdateState({
      loading: false,
      error: 'Preventivo Non Trovato!',
    });
    return;
  }

  caller.tutelaLegaleCompletedUpdateState({
    loading: false,
    quote: response.data.data,
    insurance_request: response.data.data.insurance_request,
  });
};

export const collisioneCompletedDataReset = () => {
  caller.tutelaLegaleCompletedResetState();
};

export const getCristalliCompletedData = async (quoteId) => {
  caller.tutelaLegaleCompletedUpdateState({
    loading: true,
  });

  const response = await axe.get(`/quote/cristalli/${quoteId}`);

  if (response.status !== 200) {
    generalAlertError('Preventivo Non Trovato!');
    caller.tutelaLegaleCompletedUpdateState({
      loading: false,
      error: 'Preventivo Non Trovato!',
    });
    return;
  }

  caller.tutelaLegaleCompletedUpdateState({
    loading: false,
    quote: response.data.data,
    insurance_request: response.data.data.insurance_request,
  });
};

export const cristalliCompletedDataReset = () => {
  caller.tutelaLegaleCompletedResetState();
};

export const getInfortuniConducenteCompletedData = async (quoteId) => {
  caller.tutelaLegaleCompletedUpdateState({
    loading: true,
  });

  const response = await axe.get(`/quote/infortuni-conducente/${quoteId}`);

  if (response.status !== 200) {
    generalAlertError('Preventivo Non Trovato!');
    caller.tutelaLegaleCompletedUpdateState({
      loading: false,
      error: 'Preventivo Non Trovato!',
    });
    return;
  }

  caller.tutelaLegaleCompletedUpdateState({
    loading: false,
    quote: response.data.data,
    insurance_request: response.data.data.insurance_request,
  });
};

export const infortuniConducenteCompletedDataReset = () => {
  caller.tutelaLegaleCompletedResetState();
};

export const getCommercialCompletedData = async (quoteId) => {
  caller.commercialCompletedUpdateState({
    loading: true,
  });

  const response = await axe.get(`/quote/commercial/${quoteId}`);

  if (response.status !== 200) {
    generalAlertError('Preventivo Non Trovato!');
    caller.commercialCompletedUpdateState({
      loading: false,
      error: 'Preventivo Non Trovato!',
    });
    return;
  }

  caller.commercialCompletedUpdateState({
    loading: false,
    quote: response.data.data,
    insurance_request: response.data.data.insurance_request,
  });
};

export const commercialCompletedDataReset = () => {
  caller.commercialCompletedResetState();
};

export const getMotoCompletedData = async (quoteId) => {
  caller.motoCompletedUpdateState({
    loading: true,
  });

  const response = await axe.get(`/quote/moto/${quoteId}`);

  if (response.status !== 200) {
    generalAlertError('Preventivo Non Trovato!');
    caller.motoCompletedUpdateState({
      loading: false,
      error: 'Preventivo Non Trovato!',
    });
    return;
  }

  caller.motoCompletedUpdateState({
    loading: false,
    quote: response.data.data,
    insurance_request: response.data.data.insurance_request,
  });
};

export const motoCompletedDataReset = () => {
  caller.motoCompletedResetState();
};

export const getHouseCompletedData = async (quoteId) => {
  caller.houseCompletedUpdateState({
    loading: true,
  });

  const response = await axe.get(`/quote/casa/${quoteId}`);

  if (response.status !== 200) {
    generalAlertError('Preventivo Non Trovato!');
    caller.houseCompletedUpdateState({
      loading: false,
      error: 'Preventivo Non Trovato!',
    });
    return;
  }

  caller.houseCompletedUpdateState({
    loading: false,
    quote: response.data.data,
    insurance_request: response.data.data.insurance_request,
    risk_quotations: response.data.data.risk_quotations,
  });
};

export const houseCompletedDataReset = () => {
  caller.houseCompletedResetState();
};

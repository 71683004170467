import React from 'react';
import TutelaLegaleCardResult from '../Cards/TutelaLegaleCardResult';

function ProcessingQuoteSelectedTutelaLegale({
  processingQuote,
}) {
  return (
    <>
      {processingQuote
        && (
          <div className="col-12 mb-2">
            <TutelaLegaleCardResult quote={processingQuote} />
          </div>
        )}
      {processingQuote === null && <div className="alert alert-secondary">Seleziona Quotazione!</div>}
    </>
  );
}

export default ProcessingQuoteSelectedTutelaLegale;
